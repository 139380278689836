import React from 'react';
import * as Tabs from '@radix-ui/react-tabs';

export default function ServicePackage({data}) {

  const Line = ({content}) => {
    return <div className={'pkg_des flex gap-10 ali-cen'}><img className={'check-bullet'} src="/img/check.png" alt=""/>{content}</div>
  }

  return (
  <Tabs.Root className="TabsRoot" defaultValue="tab1">

    <Tabs.List className="TabsList" aria-label="Manage your account">
      <Tabs.Trigger className="TabsTrigger" value="tab1" focus>
        <div className={'pkg_label_title'}>starter</div>
        <div className={'pkg_label_price'}>฿ 99,000</div>
      </Tabs.Trigger>
      <Tabs.Trigger className="TabsTrigger" value="tab2">
        <div className={'pkg_label_badge'}>🔥 popular</div>
        <div className={'pkg_label_title'}>midsize</div>
        <div className={'pkg_label_price'}>฿ 250,000</div>
      </Tabs.Trigger>
      <Tabs.Trigger className="TabsTrigger" value="tab3">
        <div className={'pkg_label_title'}>corporate</div>
        <div className={'pkg_label_price'}>฿ 550,000</div>
      </Tabs.Trigger>
    </Tabs.List>

    <Tabs.Content className="TabsContent" value="tab1">
      {data.package.starter.map((item, index) => {return <Line content={item} key={index}/>})}
    </Tabs.Content>
    <Tabs.Content className="TabsContent" value="tab2">
      {data.package.midsize.map((item, index) => {return <Line content={item} key={index}/>})}
    </Tabs.Content>
    <Tabs.Content className="TabsContent" value="tab3">
      {data.package.corporate.map((item, index) => {return <Line content={item} key={index}/>})}
    </Tabs.Content>

  </Tabs.Root>)
};

// export default ServicePackage;