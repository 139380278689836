import React , {useState , useEffect} from 'react';
import ReactPlayer from 'react-player'
import './App.scss';
import texts from './text.json';
import useEmblaCarousel from 'embla-carousel-react'
import ServicePackage from "./ServicePackage";
import ReviewCard from "./ReviewCard";
import ContactForm from "./ContactForm";
import LangSelector from "./LangSelector";


function App() {
  const [lang, setLang] = useState('th');
  const [isScrolled, setIsScrolled] = useState(false);
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: false })

  let data = texts[lang];

  useEffect(() => {
    if (emblaApi) {console.log(emblaApi.slideNodes()) /* Access API */}
  }, [emblaApi])

  useEffect(() => {

    const handleScroll = () => {
      const scrollHeight = window.scrollY;
      const triggerHeight = 50;
      setIsScrolled(scrollHeight > triggerHeight);
    };
    window.addEventListener('scroll', handleScroll); // 스크롤 이벤트 리스너 등록
    return () => window.removeEventListener('scroll', handleScroll); // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
  }, []);

  return (
    <div className={`App ${lang}`}>
      <div className={`nav ${isScrolled && 'bg'}`}>
        <div className={`contents`}>
          <a className={`left`} href={'#landing'}>
            <img className={`logo`} src="/img/logo.png" alt="logo"/>
            <div className={`logo-text ${isScrolled && 'dark'}`}>WELLNEXT</div>
          </a>
          <div className={`right`}>
            <a className={`item pc ${isScrolled && 'dark'}`} href={'#about'}>About</a>
            <a className={`item pc ${isScrolled && 'dark'}`} href={'#service'}>Service</a>
            <a className={`item pc ${isScrolled && 'dark'}`} href={'#facility'}>Facility</a>
            <a className={`item pc ${isScrolled && 'dark'}`} href={'#customers'}>Customers</a>
            <a className={`item pc ${isScrolled && 'dark'}`} href={'#package'}>Package</a>
            <a className={`item pc ${isScrolled && 'dark'}`} href={'#contact'}>Contact</a>
            <LangSelector lang={lang} setLang={setLang}/>
          </div>
        </div>
      </div>

      <header className="App-header">
      </header>

      <section className={"landing"} id={'landing'}>
        <img className={'landing-title'} src={"img/landing_title.png"} alt=""/>
        <div className='player-wrapper'>
          <ReactPlayer
            className={'intro-vid'}
            playing
            muted
            loop
            playsinline
            poster={'img/poster.png'}
            url={'video/intro.mp4'}
            type="video/mp4"
            width={'100vw'}
            height={'100vh'}
          />
        </div>
      </section>

      <section className={"about section ver ali-cen"} id={'about'}>
        <div className={'text-con flex ver ali-cen'}>
          <div className={'title text-center'}>{data.about.title}</div>
          <div className={'paragraph text-center'}>{data.about.description}</div>
        </div>
        <img className={'about-img'} src="/img/about.png"  alt=""/>
      </section>

      <section className={"factory"} id={'service'}>
        <div className={'section'}>
          <div className={'flex ali-cen w-75'}>
            <img src="/img/factory.png" className={'factory-img'} alt=""/>
          </div>
          <div className={'text-con flex ver w-50 gap-40'}>
            <div className={'title2 text-left w-50'}>{data.factory.title}</div>
            <div className={'paragraph2 text-left w-75'}>{data.factory.description}</div>
          </div>
        </div>
      </section>

      <section className={"facility flex ver ali-cen"} id={'facility'}>
        <div className={'section flex'}>
          <div className={'text-con flex ver w-50 gap-40 pad-side-20'}>
            <div className={'title text-left w-50'}>{data.facility.title}</div>
            <div className={'paragraph text-left w-75'}>{data.facility.description}</div>
          </div>
          <img className={'facility-img w-50'} src="/img/facility.png" alt=""/>
        </div>
        <img className={'cert_card'} src="/img/certificate_card.png" alt=""/>
      </section>

      <section className={"basic"}>
        <div className={'section flex ver ali-cen gap-40'}>
          <div className={'circle_01'}>
            <svg width="449" height="449" viewBox="0 0 449 449" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle opacity="0.3" cx="224.5" cy="224.5" r="220" stroke="#4ED3B1" strokeWidth="9"/>
            </svg>
          </div>

          <div className={'title text-center'}>{data.basic.title}</div>

          <div className={'item-con flex gap-10 w-75'}>
            <div className={'item flex ver z-1 ali-cen'}>
              <img className={'service_img'} src="/img/basic_1.png" alt=""/>
              <div className={'basic_title text-center pc'}>{data.basic.child[0].title}</div>
              <div className={'basic_des text-center'}>{data.basic.child[0].description}</div>
            </div>

            <div className={'item flex ver z-1 ali-cen'}>
              <img className={'service_img'} src="/img/basic_2.png" alt=""/>
              <div className={'basic_title text-center pc'}>{data.basic.child[1].title}</div>
              <div className={'basic_des text-center'}>{data.basic.child[1].description}</div>
            </div>

            <div className={'item flex ver z-1 ali-cen'}>
              <img className={'service_img'} src="/img/basic_3.png" alt=""/>
              <div className={'basic_title text-center pc'}>{data.basic.child[2].title}</div>
              <div className={'basic_des text-center'}>{data.basic.child[2].description}</div>
            </div>
          </div>
        </div>
      </section>

      <section className={"interpreter"}>
        <div className={'section gap-40'}>
          <img className={'interpreter-img w-50'} src="/img/interpreter.png" alt=""/>
          <div className={'text-con flex ver ali-left jus-cen w-50 gap-40'}>
            <div className={'title text-left'}>{data.interpreter.title}</div>
            <div className={'paragraph text-left'}>{data.interpreter.description}</div>
            <img className={'langs-img'} src="/img/langs.png" alt=""/>
          </div>
        </div>
      </section>

      <section className={"statics section gap-40 ali-cen"}>
        <div className={'statics-con flex gap-10 w-50'}>
          <div className={'mid-con flex ver w-50 gap-40'}>
            <div className={'flex ali-cen'}>
              <div className={'statics_title text-left'}>27 +</div>
              <div className={'statics_des text-left'}>factories in <br/> our contact pool</div>
            </div>
            <div className={'flex ali-cen'}>
              <div className={'statics_title text-left'}>34k +</div>
              <div className={'statics_des text-left'}>products produced <br/> through wellnext</div>
            </div>
          </div>

          <div className={'mid-con flex ver w-50 gap-40'}>
            <div className={'flex ali-cen'}>
              <div className={'statics_title text-left'}>85 %</div>
              <div className={'statics_des text-left'}>first time to <br/>produce products</div>
            </div>
            <div className={'flex ali-cen'}>
              <div className={'statics_title text-left'}>93 %</div>
              <div className={'statics_des text-left'}>customers reuse after <br/> first production</div>
            </div>
          </div>
        </div>
        <img className={'statics-img w-50'} src="/img/statics_map.png" alt=""/>
      </section>

      <section className={"feedback"} id={'customers'}>
        <div className={'section ver'}>
          <div className={'flex ver ali-cen'}>
            <div className={'title3 text-center'}>{data.feedback.title}</div>
            <div className={'paragraph2 text-center'}>{data.feedback.description}</div>
          </div>

          <div className={'reviews'}  ref={emblaRef}>
            <div className={"reviews-con embla__container"}>
              {data.feedback.reviews.map((item , index) => <ReviewCard key={index} item={item} /> )}
            </div>
          </div>
        </div>
        <div className={'fake-bg'} />
      </section>

      <section className={"package hidden"} id={'package'}>
        <div className={'section ver gap-40'}>
          <div className={'title text-center'}>Service Package</div>
          <ServicePackage data={data}/>
        </div>
      </section>

      <section className={'promo flex ver section ali-cen gap-10'}>
        <div className={'promo_text'}>produce safe from Korea with</div>
        <img className={'promo-img'} src="/img/promo.png" alt=""/>
      </section>

      <section className={"contact"} id={'contact'}>
        <div className={'section gap-40 m-ver'}>
          <div className={'text-con flex ver ali-left gap-40'}>
            <div className={'title text-left'}>{data.contact.title}</div>
            <div className={'paragraph text-left w-75'}>{data.contact.description}</div>
          </div>
          <ContactForm data={data.contact} />
        </div>
      </section>

      <footer className={"footer"}>
        <div className={'upper'}>
          <div className={'section'}>
            <div className={'flex ver w-50 gap-10'}>
              <div className={'flex ali-cen gap-10'}>
                <img className={'icon'} src="/img/mail_ic.png" alt="logo"/>
                <div className={'contact-info-text'}>info@wellnext.co.kr</div>
              </div>
              <div className={'flex ali-cen gap-10'}>
                <img className={'icon'} src="/img/line_ic.png" alt="logo"/>
                <div className={'contact-info-text'}>wellnext</div>
              </div>
            </div>
            <div className={'w-50 hidden'}/>
          </div>
        </div>

        <div className={'lower'}>
          <div className={"section ver ali-cen"}>
            <div className={'text_1'}>
              <a href={'#about'}>about</a>  |
              <a href={'#service'}>service</a> |
              <a href={'#contact'}>contact</a>
            </div>
            <div className={'text_2'}>We work for the success and prosperity of our customers.</div>
            <img className={'logo'} src="/img/logo.png" alt="logo"/>
            <div className={'text_3'}>ⓒ 2024 wellnext all rights reserved</div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
