import React from 'react';

export default function ReviewCard({index,item}) {
  return <div key={index} className={'review-card ver embla__slide flex gap-40 ali-cen'}>
    <img className={'quote'} src="/img/quote.png" alt="quote"/>
    <div className={'review-content'}>{item.content}</div>

    <div className={'div'} />

    <div className={'profile flex gap-10 ali-cen'}>
      <div className={'profile-img'}>
        <svg width="32" height="29" viewBox="0 0 32 29" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M23 7C23 10.866 19.866 14 16 14C12.134 14 9 10.866 9 7C9 3.13401 12.134 0 16 0C19.866 0 23 3.13401 23 7Z" fill="white"/>
          <path d="M32 32C32 40.8366 24.8366 48 16 48C7.16344 48 0 40.8366 0 32C0 23.1634 7.16344 16 16 16C24.8366 16 32 23.1634 32 32Z" fill="white"/>
        </svg>
      </div>
      <div className={'flex ver gap-6'}>
        <div className={'review-name'}>{item.name}</div>
        <div className={'review-position'}>{item.position}</div>
      </div>
    </div>
  </div>
}