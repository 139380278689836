import React, {useState} from 'react';


export default function LangSelector({lang , setLang}){
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  }
  const handleSelect = (lang) => () => {
    setLang(lang);
    setOpen(false);
  }

  const ToggleArrow = ({direction}) => {
    return (
      <svg className={`arrow ${direction}`} width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 1L5 5L1 1" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    )
  }

  return(
    <div className={'lang'}>
      <button className={'toggle'} onClick={handleOpen}>
        {lang === 'th' ? '🇹🇭' : '🇬🇧'}
        {open ?
            <ToggleArrow direction={'up'}/>
          :
            <ToggleArrow direction={'down'}/>
        }
      </button>
      {open &&
        <div className={'items-con'}>
          <button
            className={`item ${lang === 'th' && 'selected'}`}
            onClick={handleSelect('th')}
          >🇹🇭 th</button>
          <button
            className={`item ${lang === 'en' && 'selected'}`}
            onClick={handleSelect('en')}
          >🇬🇧 en</button>
        </div>
      }
    </div>
  )
};
