import React, {useState , useRef , useEffect} from 'react';
import emailjs from '@emailjs/browser';
import Lottie from 'lottie-react';
import animationData from './loading.json';


const CheckboxButton = ({label , add , sub , index}) => {
  const [state, setState] = useState(false);
  function handleOnChange() {
    setState(!state);
    state ? sub() : add();
  }

  useEffect(() => {
    if (index === 0) {
      setState(true);
      add();
    }
  }, [])

  return <label className={`checkbox-button ${state && 'selected'}`}>
    <input
      type="checkbox"
      checked={state}
      onChange={handleOnChange}
      className={'hidden'}
    />
    {label}
  </label>
}

export default function ContactForm({data}) {
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState('starter');
  const interests =[]
  const form = useRef();

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const RadioButton = ({label}) => {
    return <label className={`radio-button ${selectedOption === label ? 'selected' : ''}`}>
      <input
        type="radio"
        value={label}
        checked={selectedOption === label}
        onChange={() => handleOptionChange(label)}
        className={'hidden'}
      />
      {label}
    </label>
  }

  const sendEmail = (e) => {
    setLoading(true);
    e.preventDefault();

    const package_input = document.createElement('input');
    package_input.type = 'hidden';
    package_input.name = `req_package`;
    package_input.value = selectedOption;
    form.current.appendChild(package_input);

    const interest_input = document.createElement('input');
    interest_input.type = 'hidden';
    interest_input.name = `req_interest`;
    interest_input.value = interests;
    form.current.appendChild(interest_input);

    console.log(form.current)

    emailjs.sendForm(
      process.env.REACT_APP_EMAIL_SERVICE,
      process.env.REACT_APP_EMAIL_TEMPLATE,
      form.current,
      process.env.REACT_APP_EMAIL_PUBLIC
    )
      .then((result) => {
        console.log(result.text);
        setLoading(false);
        alert('Thank you for your interest in our services, we will get back to you soon')
      }, (error) => {
        setLoading(false);
        console.log(error.text);
        alert('something went wrong, please try again later')
      });
  };


  return (
    <form className="contact-form-con flex ver" ref={form} onSubmit={sendEmail}>
      {loading && <div className={`loading`}>
        <Lottie
          animationData={animationData} // Lottie 애니메이션 데이터(JSON)
          loop // 애니메이션 반복 여부
          autoplay // 자동으로 애니메이션 재생 여부
          style={{width: '300px', height: '300px'}} // 원하는 스타일 지정
        />
      </div>}
      <div className={'flex ver gap-10'}>
        <div className={'form-label'}>Company/Team name *</div>
        <input className={'form-input'} type="name" name={'req_name'}/>
      </div>

      <div className={'flex gap-10'}>
        <div className={'flex ver w-50 gap-10'}>
          <div className={'form-label'}>Email *</div>
          <input className={'form-input'} type="email" name={'req_email'}/>
        </div>

        <div className={'flex ver w-50 gap-10'}>
          <div className={'form-label'}>Phone number *</div>
          <input className={'form-input'} type="phone" name={'req_phone'}/>
        </div>
      </div>

      <div className={'flex ver gap-10 hidden'}>
        <div className={'form-label'}>Service Package</div>
        <div className="radio-buttons-container">
          <RadioButton label={'starter'} />
          <RadioButton label={'midsize'} />
          <RadioButton label={'corporate'} />
        </div>
      </div>

      <div className={'flex ver gap-10'}>
        <div className={'form-label'}>Main insterest</div>
        <div className="checkbox-buttons-container">
          {data.interest.map((item, index) => {
            return <CheckboxButton
              key={index}
              index={index}
              label={item}
              add={()=>interests.push(item)}
              sub={()=> interests.pop(item)}
            />
          })}
        </div>
      </div>

      <div className={'flex ver gap-10'}>
        <div className={'form-label'}>Content</div>
        <textarea className={'form-area'} rows="4" cols="50" name={'req_message'}/>
      </div>

      <button type={'submit'} className={'submit-btn'}>Submit</button>
    </form>
  );
}